import { Injectable } from '@angular/core';
import { LocalStorageRefService } from '@app/shared/services/browser/local-storage-ref.service';
import { Logger } from '@app/logger/logger';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export const LANGUAGE_APP_SETTING = 'language';

@Injectable({ providedIn: 'root' })
export class TranslateWrapperService {
  public static languages = ['en', 'es', 'fr'];

  constructor(
    private localStorageRef: LocalStorageRefService,
    private logger: Logger,
    private translateService: TranslateService
  ) {}

  get current(): string {
    return this.translateService.currentLang;
  }

  public initializeLanguage(): void {
    const navLanguage = navigator.language.split('-')[0];
    const localLang = this.localStorageRef.language;
    const selectedLang = this.isLangValid(localLang)
      ? localLang
      : this.isLangValid(navLanguage)
      ? navLanguage
      : '';

    this.translateService.addLangs(TranslateWrapperService.languages);
    this.setDefaultLang();

    if (selectedLang) {
      this.logger.info('Browser language set to', selectedLang);
      this.use(selectedLang);
    } else {
      this.logger.info(`Current languages (${localLang}, ${navLanguage}) not supported`);
      this.use('en');
    }

    this.localStorageRef.language = this.current;
  }

  public setDefaultLang(lang?: string): void {
    this.translateService.setDefaultLang(lang ?? 'en');
  }

  public use(lang: string): void {
    this.translateService.use(lang);
  }

  public get(key: string | string[], interpolateParams?: Object): Observable<any> {
    return this.translateService.get(key, interpolateParams);
  }

  public instant(key: string | string[], interpolateParams?: Object): any {
    return this.translateService.instant(key, interpolateParams);
  }

  public update(lang: string): void {
    if (!this.isLangValid(lang)) {
      this.logger.warn('Invalid language selected');
      return;
    }

    const currentLanguage = this.localStorageRef.language;

    if (currentLanguage === lang) {
      return;
    } else {
      this.localStorageRef.language = lang;
      this.use(lang);
    }
  }

  public isLangValid(lang: string): boolean {
    return TranslateWrapperService.languages.includes(lang);
  }
}
